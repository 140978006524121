import {
  mdiWeb,
  mdiViewDashboardVariantOutline,
  mdiFolderOutline,
  mdiTagOutline,
  mdiApplicationCog,
  mdiVectorSquare,
  mdiAlphaE,
  mdiFire,
  mdiCounter, mdiAccount, mdiAccountGroup
} from '@mdi/js'

export default [
  {
    title: 'Kanäle',
    icon: mdiWeb    ,
    to: 'kanal',
  },
  {
    title: 'Kampagnen',
    icon: mdiViewDashboardVariantOutline  ,
    to: 'kampagne',
  },
  {
    title: 'Empfänger',
    icon: mdiAccountGroup,
    to: 'empfaenger',
  },
  {
    title: 'Sonstiges',
    icon: mdiApplicationCog ,
    children: [
      {
        title: 'Events',
        icon: mdiFire ,
        to: 'event',
      },
      {
        title: 'Tags',
        icon: mdiTagOutline,
        to: 'tag',
      },
      {
        title: 'Themenbereiche',
        icon: mdiFolderOutline,
        to: 'topic',
      },
      {
        title: 'Segmente',
        icon: mdiVectorSquare  ,
        to: 'segment',
      },
      {
        title: 'Attribute',
        icon: mdiCounter,
        to: 'attribute',
      },
    ]
  },
]
